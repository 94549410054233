import store from '../../store'
import { sortBy } from 'lodash/collection'
import _ from 'lodash'

//------------------------------- LOAD OPTIONAL CATEGORY PRODUCTS -------------------------------//
export function loadOptionalCategoryProducts (optionalCategories, skipCategoryNames = []) {
  const optionalCategoriesProducts = []
  const allOptionalCategoryProducts = []
  optionalCategories.forEach(category => {
    if (skipCategoryNames.includes(category.name)) return
    // If monitoring already exists skip adding
    if (!monitoringTrialEligible(category)) return

    const addProductParams = {
      category,
      optionalCategoriesProducts,
      allOptionalCategoryProducts,
    }

    if (category.product_override) addProductsByProductOverride(addProductParams)
    if (category.product_ids) addProductsByProductIds(addProductParams)
  })

  return [optionalCategoriesProducts, allOptionalCategoryProducts]
}

function addProductsByProductOverride ({
  category,
  optionalCategoriesProducts,
  allOptionalCategoryProducts,
}) {
  const productKind = category.product_kind

  if (['simple_product', 'filing_product', 'registered_agent_product', 'supply_and_consult_product'].includes(productKind)) {
    const filingProductHasMultipleProducts = ['EIN - Tax ID'].includes(category.name)

    category.product_override.forEach(product_override_name => {
      const product = store.getters['checkout/findProductBy'](
        productKind,
        { name: product_override_name }
      )

      if (!eligibleToOrderProduct(product)) return

      if (filingProductHasMultipleProducts && product) createParentFilingCategoryProduct(
        category,
        product,
        optionalCategoriesProducts
      )

      const parentCategory =
        optionalCategoriesProducts.find(c => c.filingName && c.filingName === product?.filing_name) || null

      const params = {
        product,
        category,
        optionalCategoriesProducts,
        allOptionalCategoryProducts,
        parentCategory,
      }

      if (product) addOptionalCategoryProduct(params)
    })
  }
}

function addProductsByProductIds ({
  category,
  optionalCategoriesProducts,
  allOptionalCategoryProducts,
}) {
  const categoryHasMultipleProducts = category.product_ids.length > 1 &&
    ['Open and Scan'].includes(category.name)

  if (categoryHasMultipleProducts) createParentCategoryProduct(category, optionalCategoriesProducts)

  const parentCategory = categoryHasMultipleProducts ?
    optionalCategoriesProducts.find(c => c.categoryName === category.name) :
    null

  category.product_ids.forEach(productId => {
    const product = store.getters['checkout/allProducts'].find(product => product.id === productId)
    const params = {
      product,
      category,
      optionalCategoriesProducts,
      allOptionalCategoryProducts,
      parentCategory,
    }

    if (product) addOptionalCategoryProduct(params)
  })
}

function addOptionalCategoryProduct ({
  product,
  category,
  optionalCategoriesProducts,
  allOptionalCategoryProducts,
  parentCategory = null,
}) {
  if (!eligibleToOrderProduct(product)) return

  const productClone = _.cloneDeep(product)

  // Adjust the price to account for Trial Products
  productClone.displayPrice = productPrice(productClone)
  productClone.description = category.description
  productClone.imageKey = category.image_key

  if (parentCategory) {
    productClone.displayName = parentCategory?.filingName ? category.name : productClone.name
    parentCategory['productIds'].push(productClone.id)
    parentCategory['products'].push(productClone)
  } else {
    const displayName = category.name === 'Open and Scan' ? productClone.name : category.name
    productClone.displayName = displayName
    optionalCategoriesProducts.push(productClone)
  }

  if (productClone.kind === 'filing_product') {
    const filingMethod = setDefaultFilingMethod(productClone)
    if (filingMethod) productClone.defaultFilingMethod = filingMethod
  }

  allOptionalCategoryProducts.push(productClone)
}

function createParentFilingCategoryProduct(category, product, optionalCategoriesProducts) {
  optionalCategoriesProducts.push({
    displayName: category.name,
    description: category.description,
    imageKey: category.image_key,
    categoryName: category.name,
    filingName: product.filing_name,
    displayPrice:'',
    products: [],
    productIds: [],
  })
}

function createParentCategoryProduct(category, optionalCategoriesProducts) {
  optionalCategoriesProducts.push({
    displayName: category.name === 'Open and Scan' ? 'Mail Service' : category.name,
    description: category.description,
    imageKey: category.image_key,
    categoryName: category.name,
    products: [],
    productIds: [],
  })
}

function getJurisdictionOfCurrentProduct() {
  const currentProduct = store.getters['checkout/currentProduct']
  const currentProductInCart = store.getters['checkout/findCartItem'](currentProduct.id)

  let jur = null
  if (currentProduct?.filing_method) {
    jur = store.getters['schema/currentObject']?.jurisdiction
  }
  else if (currentProduct?.filing_methods?.length && currentProductInCart) {
    jur = sortBy(currentProduct.filing_methods.filter(fm =>
        fm.id === currentProductInCart?.filing_method_id &&
        fm.purchasable === true), 'cost')[0]?.jurisdiction
  }
  else if (currentProduct?.products?.[0]?.filing_methods?.length) {
    jur = sortBy(currentProduct.products[0].filing_methods.filter(fm =>
      fm.purchasable === true), 'cost')[0]?.jurisdiction
  }
  return jur
}

function eligibleToOrderProduct(product) {
  if (['operating agreement', 'corporate bylaws'].includes(product?.category)) {
    return !store.getters['companies/activeOrderItemsByProductName'](product?.name)
  }

  if (product?.kind ===  'product_bundle') {
    return !store.getters['companies/activeProductBundles']
      ?.some(productBundle => productBundle.id === product.id)
  }

  if (product?.category === 'compliance') {
    return getJurisdictionOfCurrentProduct() !== 'Federal'
  }

  if (product?.filing_name === 'trade name') {
    const noDBAOptionalJurs = ['New Mexico', 'West Virginia']
    const currentProduct = store.getters['checkout/currentProduct']
    const jur = getJurisdictionOfCurrentProduct()
    return !((currentProduct?.filing_name === 'form a company'
      || currentProduct?.products[0]?.filing_name === 'form a company')
      && noDBAOptionalJurs.includes(jur))
  }

  return ['tax id', 'publication', 's corp'].includes(product?.filing_name) ?
    !store.getters['companies/activeOrderItemsByFilingName'](product?.filing_name) :
    true
}

function _parentFilingCategoryProductPriceRange(filingName) {
  const filingProducts = store.getters['checkout/findProductsBy'](
    'filing_product',
    { filing_name: filingName }
  )
  const filingProductPrices = []

  if (filingProducts.length > 1) {
    filingProducts.forEach(filingProduct => {
      const filingMethodCost =  filingProduct.price + determineFilingMethod(filingProduct).cost
      if (filingMethodCost > 0) filingProductPrices.push(filingMethodCost)
    })

    const minPrice = Math.min.apply(Math, filingProductPrices)
    const maxPrice = Math.max.apply(Math, filingProductPrices)
    const price = '$' + minPrice + ' - $' + maxPrice
    return price
  } else {
    const filingMethodCost = filingProducts[0].price + determineFilingMethod(filingProducts[0]).cost
    return filingMethodCost ? '$' + filingMethodCost : ''
  }
}

function productPrice(product) {
  return isTrialPrice(product) ?
    trialText(product) :
    product?.filing_name === 'change registered agent' ?
      'Varies' : determineDisplayPrice(product)
}

function isTrialPrice(product) {
  return phoneTrialEligible(product.category) || lawOnCallTrialEligible(product.category)
}

function trialText(item) {
  return `Free Trial${item?.category === 'law-on-call' ? ' - 60 Days' : ''}`
}

function lawOnCallTrialEligible(category) {
  return store.getters['lawOnCall/lawOnCallTrialEligible'] && category === 'law-on-call'
}

function phoneTrialEligible(category) {
  return store.getters['virtualPhones/phoneServiceTrialEligible'] && category === 'virtual-phone'
}

function monitoringTrialEligible(category) {
  if (category.name !== 'Monitoring') return true
  return store.getters['monitoring/monitoringTrialEligible']
}

function determineDisplayPrice(product) {
  if (product.displayPrice && typeof (product.displayPrice) === 'string') return product.displayPrice

  let price = product.price

  if (product.kind === 'filing_product') {
    price += determineFilingMethod(product).cost
  }

  const duration = product?.renewable ? (product?.duration === 12 ? ' Yearly' :  ' Monthly') : ''

  return price ? '$' + price + duration : 'Free'
}

function setDefaultFilingMethod(product) {
  let method
  switch (product.displayName) {
    case 'EIN - Tax ID':
      method = product.filing_methods.find(fm => fm.type === 'online')
      break
    case 'EIN - Tax ID : Non US Citizen':
      method = product.filing_methods.find(fm => fm.type === 'mail')
      break
    default:
      method = determineFilingMethod(product)
  }
  return method
}

function determineFilingMethod(product) {
  let filingMethod = null
  const currentProduct = store.getters['checkout/currentProduct']

  if (product.filing_methods.length === 1) {
    filingMethod = product.filing_methods[0]
  } else {
    // 1 - Find Filing Method based off of the Current Product -> Filing Method
    if (['filing_product', 'product_bundle'].includes(currentProduct?.kind)) {
      let jurisdiction = null
      const currentProductInCart = store.getters['checkout/findCartItem'](currentProduct.id)

      if (currentProduct?.kind === 'product_bundle') {
        jurisdiction = Object.values(currentProductInCart.data).find(product =>
          product &&
          'jurisdiction' in product
        )?.jurisdiction || null
      } else {
        // If Current Product has a filing_method it will be tied to the current object so use that
        // that to get the jurisdiction. Otherwise check the filing methods
        jurisdiction = currentProduct?.filing_method ?
          store.getters['schema/currentObject']?.jurisdiction :
          currentProduct?.filing_methods?.length && currentProductInCart ?
            sortBy(
              currentProduct.filing_methods.filter(fm =>
                fm.id === currentProductInCart?.filing_method_id &&
                fm.purchasable === true
              ), 'cost'
            )[0]?.jurisdiction :
            null
      }

      if (jurisdiction) filingMethod = findFilingMethodByJurisdiction(product, jurisdiction)

      if (isDbaProduct(product)) {
        formatDbaProduct(product, filingMethod, jurisdiction)

        if (!filingMethod) filingMethod = 'none'
      }
    }

    // 2 - Find Filing Method based off of the current product
    if (!filingMethod && currentProduct?.jurisdiction) {
      filingMethod = findFilingMethodByJurisdiction(product, currentProduct.jurisdiction)
    }

    // 3 - Find Filing Method based off of the companies jurisdiction
    if (!filingMethod && companiesDomesticJurisdiction) {
      filingMethod = findFilingMethodByJurisdiction(product, companiesDomesticJurisdiction)
    }
  }

  return filingMethod || product.filing_methods[0]
}

function findFilingMethodByJurisdiction(product, jurisdiction) {
  const state_province_region = (typeof(jurisdiction) === 'object') ? jurisdiction.state_province_region : jurisdiction
  return sortBy(
    product.filing_methods.filter(fm => fm.jurisdiction === state_province_region && fm.purchasable === true),
    'cost'
  )[0]
}

function companiesDomesticJurisdiction() {
  return store.getters['companies/domesticRegistration']?.jurisdiction?.state_province_region
}

function formatDbaProduct(product, filingMethod, jurisdiction) {
  product.stateJurisdiction = jurisdiction

  if (filingMethod?.local_jurisdiction_id) {
    product.optionalFilingMethods = sortBy(
      product.filing_methods.filter(fm => fm.jurisdiction === jurisdiction && fm.purchasable === true),
      'cost'
    )

    product.displayPrice = ''
  }
}

function isDbaProduct(product) {
  return product.displayName === 'Trade Name (DBA)' || product.displayName === 'Assumed Name (DBA)'
}

//---------------------------- ADD OPTIONAL CATEGORY PRODUCT TO CART ----------------------------//
export async function optionalItemSelected (item, selected) {
  if (item.kind === 'filing_product') {
    await filingOptionalItemSelected(item, selected)
  } else if (item.kind === 'product_bundle') {
    await productBundleOptionalItemSelected(item, selected)
  } else {
    await standardOptionalItemSelected(item, selected)
  }
}

async function standardOptionalItemSelected (item, selected) {
  const parentProduct = store.getters['checkout/currentProduct']
  const parentProductInCart = store.getters['checkout/findCartItem'](parentProduct.id)

  if (selected) {
    if (store.getters['checkout/findCartItem'](item.id)) return

    const product = {
      ...item,
      type: item.kind,
      optionalAddOn: true,
      skipResolveOptionalItems: true,
    }

    if (item.name === 'Renewal Service') {
      if (parentProduct?.kind === 'product_bundle') {
        const filingProductInCartData = Object.entries(parentProductInCart.data).find(([_, productData]) => {
          return productData.kind === "filing_product"
        })

        if (filingProductInCartData) {
          const [_productId, filingProduct] = filingProductInCartData
          const jurisdiction = filingProduct.filing_method?.jurisdiction || null
          if (jurisdiction)  product.data = { state: jurisdiction }
        }

        await store.dispatch('checkout/addToCart', product)
      } else if (parentProductInCart || parentProduct.kind === 'registered_agent_product') {
        configureToggleOptionalItem('compliance', parentProduct?.filing_name || '')
      } else {
        const activeFiling = store.getters['companies/activeFilingByFilingName'](parentProduct.filing_name)

        const jurisdiction = activeFiling?.jurisdiction ||
          parentProduct?.filing_method?.jurisdiction ||
          null

        if (jurisdiction) product.data = { state: jurisdiction }

        await store.dispatch('checkout/addToCart', product)
      }
    } else if (item.category === 'mail-forwarding') {
      product.data = { state: item.schema[0].meta.default }
      await store.dispatch('checkout/addToCart', product)
    } else {
      await store.dispatch('checkout/addToCart', product)
    }
  } else {
    const toggleOptionalItem = !!parentProductInCart
    await removeItemFromCart(item, parentProduct, toggleOptionalItem)
  }
}

async function filingOptionalItemSelected (item, selected) {
  if (selected) {
    let itemInCart = store.getters['checkout/findCartItem'](item.id)
    if (isDbaProduct(item) || item.filing_name === 'beneficial ownership information report') {
      itemInCart = store.getters['checkout/findCartItemByFilingMethodId'](item.defaultFilingMethod.id)
    }

    if ((itemInCart && !isDbaProduct(item)) ||
      (isDbaProduct(item) && itemInCart?.filing_method_id === item.defaultFilingMethod.id)) return

    if ((itemInCart && item.filing_name !== 'beneficial ownership information report') ||
      (item.filing_name === 'beneficial ownership information report' && itemInCart?.filing_method_id === item.defaultFilingMethod.id)) return

    if (item.filing_name === 'change registered agent') {
      configureToggleOptionalItem('changeOfAgent')
    } else {
      const product = {
        ...item,
        kind: 'filing_products',
        filingMethodId: item.defaultFilingMethod.id,
        optionalAddOn: true,
        skipResolveOptionalItems: true,
      }
      await store.dispatch('checkout/addToCart', product)
    }
  } else {
    await removeItemFromCart(item)
  }
}

async function productBundleOptionalItemSelected(item, selected) {
  if (selected) {
    // Note - This only works for bundles with simple products.
    // will need to update this for bundles with Filing Products or RA is included later when that
    // functionality is needed
    await store.dispatch('checkout/checkForBundleFreeTrialsAndCalculateTotal', {
      productBundle: item,
    })
    await store.dispatch('checkout/addBundleToCart', {
      bundle: {
        ...item,
        data: store.getters['checkout/currentBundleItems'],
        skipResolveOptionalItems: true,
      },
    })
  } else {
    await removeItemFromCart(item)
  }
}
async function removeItemFromCart (item, parentProduct = null, toggleOptionalItem = true) {
  if (item?.filing_name === 'change registered agent' && toggleOptionalItem) {
    configureToggleOptionalItem('changeOfAgent', '', false)
  } else if (item.name === 'Renewal Service' && toggleOptionalItem && parentProduct?.kind !== 'product_bundle') {
    configureToggleOptionalItem('compliance', '', false)
  } else if (isDbaProduct(item) || item.filing_name === 'beneficial ownership information report') {
    const id = store.getters['checkout/findCartItemByFilingMethodId'](item.defaultFilingMethod.id)?.id
    if (id) await store.dispatch('checkout/removeFromCart', { id })
  } else {
    if (item?.id) {
      const id = store.getters['checkout/findCartItem'](item.id)?.id
      if (id) await store.dispatch('checkout/removeFromCart', { id })
    }
  }
}

function configureToggleOptionalItem(
  name,
  parentFilingProduct = '',
  optionalAddOn = true
) {
  store.dispatch('checkout/toggleHireUsOptionalItem', {
    name: name,
    optionalAddOn: optionalAddOn,
    optionalParentFilingProduct: parentFilingProduct,
  })
}
